@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  min-height: 100vh;
}

main {
  min-height: 100vh;
}

.borderArrowRight {
  padding-top: 2rem;
  padding-bottom: 2rem;
  position: relative;
}

.borderArrowRight::before,
.borderArrowRight::after {
  content: "";
  height: 55px;
  width: 1px;
  position: absolute;
  background: hsl(var(--n));
  right: 0;
}

.borderArrowRight::before {
  transform: rotate(-35deg);
  top: -6px;
}

.borderArrowRight::after {
  transform: rotate(35deg);
  bottom: -6px;
}

.collapse-plus .collapse-title:after {
  height: auto !important;
  width: auto !important;
  top: auto !important;
}

.checkbox {
  position: relative !important;
}

.checkbox.checkbox-white:checked,
.checkbox.checkbox-white[checked="true"],
.checkbox.checkbox-white[aria-checked="true"] {
  background-image: linear-gradient(-45deg, transparent 65%, hsl(0, 100%, 100%) 65.99%),
    linear-gradient(45deg, transparent 75%, hsl(0, 100%, 100%) 75.99%),
    linear-gradient(-45deg, hsl(0, 100%, 100%) 40%, transparent 40.99%),
    linear-gradient(
      45deg,
      hsl(0, 100%, 100%) 30%,
      hsl(0, 100%, 100%) 30.99%,
      hsl(0, 100%, 100%) 40%,
      transparent 40.99%
    ),
    linear-gradient(-45deg, hsl(0, 100%, 100%) 50%, hsl(0, 100%, 100%) 50.99%);
}

.checkbox.checkbox-white:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 6px;
  height: 13px;
  border: solid #d66727;
  border-width: 0 2px 2px 0;
  transform: translate(-50%, -50%) rotate(45deg);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.checkbox.checkbox-white:checked:before {
  opacity: 1;
}

.checkbox.primary:checked,
.checkbox.primary[checked="true"],
.checkbox.primary[aria-checked="true"] {
  background-image: linear-gradient(-45deg, transparent 65%, hsl(var(--p)) 65.99%),
    linear-gradient(45deg, transparent 75%, hsl(var(--p)) 75.99%),
    linear-gradient(-45deg, hsl(var(--p)) 40%, transparent 40.99%),
    linear-gradient(
      45deg,
      hsl(var(--p)) 30%,
      hsl(var(--p)) 30.99%,
      hsl(var(--p)) 40%,
      transparent 40.99%
    ),
    linear-gradient(-45deg, hsl(var(--p)) 50%, hsl(var(--p)) 50.99%);
}

.checkbox.primary:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: translate(-50%, -50%) rotate(45deg);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.checkbox.primary:checked:before {
  opacity: 1;
}

.ql-container {
  @apply rounded-b min-h-[7rem];
}

.ql-editor {
  @apply min-h-[7rem];
}

.ql-toolbar {
  @apply rounded-t;
}

.ql-editor p {
  @apply text-sm;
}

[contenteditable="false"] {
  @apply bg-neutral;
}

.ql-snow .ql-tooltip {
  @apply !left-0;
}

.ql-editor ul > li::before {
  @apply !text-center;
}

.ql-picker-options {
  @apply !z-[1800] !bg-white;
}

.ql-picker-item {
  @apply !z-[1800] !bg-white;
}

:root {
  /* an opacity version of the primary colour, used for the scrollbar */
  --scrollbar-colour: #9ca3af;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-colour) transparent;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
  opacity: 0.2;
}

*::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-colour);
  border-radius: 14px;
}

.table th,
.table td {
  @apply !p-4;
  vertical-align: middle;
}

.btn-citation {
  @apply align-text-top ml-1 border border-blueGrey-100 bg-gray-50 text-blueGrey-400 rounded-full px-[6px] py-[2px] text-[10px] leading-[14px] w-fit hover:bg-gray-800 transition-all hover:text-white;
}

:target,
.highlight {
  background-color: #ffff99;
  width: fit-content;
  transition: background-color 0.5s ease;
}
